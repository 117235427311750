import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { useSession } from '../util/context.js'

export default props => {
    const { user } = useSession()
    const loc = useLocation()
    const q = new URLSearchParams(loc.search)
    const price = q.get('price')
    const courseId = q.get('courseId')
    const next = q.get('next')
    return (
        <div className="max-w-md h-screen m-auto flex-center">
            <div className="bg-white relative border-t-4 border-teal-400 rounded-lg shadow-2xl pt-8 pb-10 px-4 sm:px-8 text-center max-w-sm flex flex-col items-center">
                <Link
                    to="/"
                    className="absolute right-0 top-0 mt-2 mr-2 text-gray-500 rounded-full bg-gray-100 hover:bg-gray-200 flex-center h-6 w-6">
                    <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </Link>
                <div className="rounded-full bg-teal-200 flex-center h-12 w-12">
                    <svg
                        className="h-6 w-6 text-teal-900"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                        />
                    </svg>
                </div>
                <h1 className="my-3 font-semibold text-lg">Payment Suceeded</h1>
                <p className="text-sm text-gray-800 px-1 my-2">
                    Thanks!! <br />
                    Your payment <strong>{price ? `of ${price}` : ''}</strong> was successful. You
                    can now access this course. Your receipt has been sent to
                    {!!user ? (
                        <span className="font-medium"> {user.email}</span>
                    ) : (
                        <span> your email</span>
                    )}
                </p>
                {courseId && next ? (
                    <Link
                        replace
                        to={`/courses/${courseId}/${next}`}
                        className={`w-full mt-4 py-3 px-8 font-medium text-white rounded bg-gray-800 focus:bg-gray-900 hover:bg-gray-700
focus:outline-none focus:shadow-outline text-sm leading-5`}>
                        Start Course
                    </Link>
                ) : (
                    <Link
                        replace
                        to="/"
                        className={`w-full mt-4 py-3 px-8 font-medium text-white rounded bg-gray-800 focus:bg-gray-900 hover:bg-gray-700
focus:outline-none focus:shadow-outline text-sm leading-5`}>
                        Go Home
                    </Link>
                )}
                <Link
                    replace
                    to="/app/my-courses"
                    className={`w-full mt-4 py-3 px-8 font-medium border border-gray-300 text-gray-900 rounded
hover:border-gray-700 focus:outline-none focus:shadow-outline text-sm leading-5`}>
                    Go to Dashboard
                </Link>
            </div>
        </div>
    )
}
